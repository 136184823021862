import React, {useEffect, useRef, useState} from 'react';
import AppContainer from "../../containers/AppContainer";
import {getLcBookings} from "../../services/firebaseNodes";
import {LcBookingsInterface} from "../../interfaces/common";
import moment from "moment";
import { CSVLink, CSVDownload } from "react-csv";

const LcBookings = () => {

  const textAreaRef = useRef();
  const [emailList, setEmailList] = useState("");
  const [startDateTsState, setStartDateTsState] = useState<Date>(new Date());
  const [endDateTsState, setEndDateTsState] = useState<Date>(new Date());
  const [lcBookingStateData, setLcBookingStateData] = useState<LcBookingsInterface[]>([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [lcCount, setLcCount] = useState({});
  const [csvData, setCsvData] = useState<any>([]);



  
  async function handleClick() {
    setErrorMsg("")
    const counts = {}
    const startDate = new Date(startDateTsState).getTime();
    const endDate = new Date(endDateTsState).getTime();
    const getLcBookingJson = await getLcBookings(startDate, endDate);
    if (getLcBookingJson.exists()) {

      const lcBookingDataArray: LcBookingsInterface[] = [];
      const lcBookingDataLcList: string[] = []
      getLcBookingJson.forEach(lcBookingItem => {
        lcBookingDataArray.push(lcBookingItem.val());
        lcBookingDataLcList.push(lcBookingItem.val().lcId)
      });
      setTotalCount(getLcBookingJson.size)
      setLcBookingStateData(lcBookingDataArray);

      lcBookingDataLcList.forEach((item: string) => {
        console.log(item)
        // @ts-ignore
        counts[item] = (counts[item] || 0) + 1;
      })

      setLcCount(counts)


      console.log(counts)


    } else {
      setErrorMsg("No data found");
    }
  }


  useEffect(() => {
    const csvDataFn = Object.values(lcBookingStateData).map(
      (item: any) => {
        return [
          item.userId,
item.lcId,
item.bookingTs ? moment(item.bookingTs).format("MMM YYYY") : "",
item.userName,
item.userEmail,
item.teacherId,
item.lcCategoryId,
item.lcCourseId,
item.lcEndDateTs ? moment(item.lcEndDateTs).format("MMM YYYY") : "",
item.lcLevelId,
item.lcProgramId,
item.lcStartDateTs ? moment(item.lcStartDateTs).format("MMM YYYY") : "",
item.lcSupportId,
item.lcTargetLang,
item.Support,
item.lpId,
item.teacherName,
item.lcId,
item.lcStartDateTs ? moment(item.lcStartDateTs).format("DD MMM YYYY") : "",
item.lcEndDateTs ? moment(item.lcEndDateTs).format("DD MMM YYYY") : "",
item.bookingTs ? moment(item.bookingTs).format("DD MMM YYYY") : "",

        
        ];
      }
    );
  
    const newCsvData = [
      [ 
        "USER ID",
"LIVE CLASS ID",
"BOOKING MONTH",
"USER NAME",
"EMAIL",
"TEACHER ID",
"CATEGORY",
"COURSE",
"CLASS START MONTH",
"LEVEL",
"PROGRAM",
"CLASS END MONTH",
"SUPPORT",
"TARGET LANGUAGE",
"SUPPORT",
"LIVE CLASS",
"teacherName",
"LIVE CLASS RECURRENCE",
"CLASS END DATE",
"CLASS START DATE",
"BOOKING DATE",
      ],
      ...csvDataFn,
    ];
  
    setCsvData(newCsvData);
    console.log(newCsvData);
  }, [lcBookingStateData])


  function handleOnChange(e: any) {
    if (e.target.value) {
      setEmailList(e.target.value.split('\n'));
      console.log(emailList);

    }
  }


  return (
    <AppContainer headerTitle={"Class Report"}>
      <div className="container paper">
        <div className="row ">
          <div className="column ">

            <input type={"date"} onChange={(e: any) => setStartDateTsState(e.target.value)}/>
       

            <input type={"date"} onChange={(e: any) => setEndDateTsState(e.target.value)}/>
    

            <button className="button button-clear" onClick={handleClick}>Submit</button>
         
          {lcBookingStateData && lcBookingStateData.length > 0 && (
              <CSVLink data={csvData} className='button button-clear'>Download as CSV</CSVLink>
            )}
            <hr />

  {errorMsg !== "" ? errorMsg : ""}
          </div>

        
        </div>
    

      
        {/* <div className="row">


          <div className="column">
            <h3>Live Class bookings per class</h3>
            <table className={"bg-light"}>
              <th>Live Class Title</th>
              <th>Bookings</th>
              {

                Object.entries(lcCount).map((value) =>
                  <tr key={value[0]}>
                    <td>{String(value[0])}</td>

                    <td>{String(value[1])}</td>


                  </tr>
                )
              }

            </table>
          </div>

        </div> */}
        {/* <hr/> */}
        {/* <div className="row">
          <h3>Booking Info per student</h3>

        </div>
        <div className="row">


          <table className={"bg-light"}>
            <thead>
            <tr>
              <th>userId</th>
              <th>lcId</th>
              <th>bookingTs</th>
              <th>userName</th>
              <th>userEmail</th>
              <th>teacherId</th>
              <th>lcCategoryId</th>
              <th>lcCourseId</th>
              <th>lcEndDateTs</th>
              <th>lcLevelId</th>
              <th>lcProgramId</th>
              <th>lcStartDateTs</th>
              <th>lcSupportId</th>
              <th>lcTargetLang</th>
              <th>Support</th>
              <th>lpId</th>
              <th>teacherName</th>
              <th>Live Class</th>
            </tr>
            </thead>
            <tbody>
            {
              lcBookingStateData.length > 0 ? lcBookingStateData.map((item, index) =>
                <tr key={index}>
                  <td>{item.userId}</td>
                  <td>{item.lcId}</td>
                  <td>{item.bookingTs ? moment(item.bookingTs).format("MMM YYYY") : ""}</td>
                  <td>{item.userName}</td>
                  <td>{item.userEmail}</td>
                  <td>{item.teacherId}</td>
                  <td>{item.lcCategoryId}</td>
                  <td>{item.lcCourseId}</td>
                  <td>{item.lcEndDateTs ? moment(item.lcEndDateTs).format("MMM YYYY") : ""}</td>
                  <td>{item.lcLevelId}</td>
                  <td>{item.lcProgramId}</td>
                  <td>{item.lcStartDateTs ? moment(item.lcStartDateTs).format("MMM YYYY") : ""}</td>
                  <td>{item.lcSupportId}</td>
                  <td>{item.lcTargetLang}</td>
                  <td>{item.lcSupportId}</td>
                  <td>{item.lpId}</td>
                  <td>{item.teacherName}</td>
                  <td>{item.lcId}</td>
                </tr>
              ) : null
            }

            </tbody>
          </table>
        </div> */}
      </div>
    </AppContainer>
  );
}


export default LcBookings;
