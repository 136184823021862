import { faker } from "@faker-js/faker";

export function getSwitchValue(childSelected: string, parentSelected: string) {
  let fakerTempValue = "";
  // const customCase = childSelected;
  switch (childSelected) {
    // case customCase:
    //   console.log("customCase", customCase);
    //   const temp = Object.entries(fakeDataChildList);
    //   const newTemp = temp.find(([r]) => r === customCase);
    //   if (newTemp) fakerTempValue = String(randomFromArray(newTemp[1]));
    //
    //   break;
    case "buildingNumber":
      fakerTempValue = faker.address.buildingNumber();
      break;
    case "cardinalDirection":
      fakerTempValue = faker.address.cardinalDirection();
      break;
    case "city":
      fakerTempValue = faker.address.city();
      break;
    case "cityName":
      fakerTempValue = faker.address.cityName();
      break;
    case "cityPrefix":
      fakerTempValue = faker.address.cityPrefix();
      break;
    case "citySuffix":
      fakerTempValue = faker.address.citySuffix();
      break;
    case "country":
      fakerTempValue = faker.address.country();
      break;
    case "countryCode":
      fakerTempValue = faker.address.countryCode();
      break;
    case "county":
      fakerTempValue = faker.address.county();
      break;
    case "direction":
      fakerTempValue = faker.address.direction();
      break;
    case "latitude":
      fakerTempValue = faker.address.latitude();
      break;
    case "longitude":
      fakerTempValue = faker.address.longitude();
      break;
    case "ordinalDirection":
      fakerTempValue = faker.address.ordinalDirection();
      break;
    case "secondaryAddress":
      fakerTempValue = faker.address.secondaryAddress();
      break;
    case "state":
      fakerTempValue = faker.address.state();
      break;
    case "stateAbbr":
      fakerTempValue = faker.address.stateAbbr();
      break;
    case "street":
      fakerTempValue = faker.address.street();
      break;
    case "streetAddress":
      fakerTempValue = faker.address.streetAddress();
      break;
    case "streetName":
      fakerTempValue = faker.address.streetName();
      break;
    case "streetPrefix":
      fakerTempValue = faker.address.streetPrefix();
      break;
    case "streetSuffix":
      fakerTempValue = faker.address.streetSuffix();
      break;
    case "timeZone":
      fakerTempValue = faker.address.timeZone();
      break;
    case "zipCode":
      fakerTempValue = faker.address.zipCode();
      break;
    case "bear":
      fakerTempValue = faker.animal.bear();
      break;
    case "bird":
      fakerTempValue = faker.animal.bird();
      break;
    case "cat":
      fakerTempValue = faker.animal.cat();
      break;
    case "cetacean":
      fakerTempValue = faker.animal.cetacean();
      break;
    case "cow":
      fakerTempValue = faker.animal.cow();
      break;
    case "crocodilia":
      fakerTempValue = faker.animal.crocodilia();
      break;
    case "dog":
      fakerTempValue = faker.animal.dog();
      break;
    case "fish":
      fakerTempValue = faker.animal.fish();
      break;
    case "horse":
      fakerTempValue = faker.animal.horse();
      break;
    case "insect":
      fakerTempValue = faker.animal.insect();
      break;
    case "lion":
      fakerTempValue = faker.animal.lion();
      break;
    case "rabbit":
      fakerTempValue = faker.animal.rabbit();
      break;
    case "rodent":
      fakerTempValue = faker.animal.rodent();
      break;
    case "snake":
      fakerTempValue = faker.animal.snake();
      break;
    case "type":
      fakerTempValue = faker.animal.type();
      break;

    case "cssSupportedFunction":
      fakerTempValue = faker.color.cssSupportedFunction();
      break;
    case "cssSupportedSpace":
      fakerTempValue = faker.color.cssSupportedSpace();
      break;

    case "human":
      fakerTempValue = faker.color.human();
      break;
    case "rgb":
      fakerTempValue = faker.color.rgb();
      break;
    case "space":
      fakerTempValue = faker.color.space();
      break;
    case "color":
      fakerTempValue = faker.commerce.color();
      break;
    case "department":
      fakerTempValue = faker.commerce.department();
      break;
    case "price":
      fakerTempValue = faker.commerce.price();
      break;
    case "product":
      fakerTempValue = faker.commerce.product();
      break;
    case "productAdjective":
      fakerTempValue = faker.commerce.productAdjective();
      break;
    case "productDescription":
      fakerTempValue = faker.commerce.productDescription();
      break;
    case "productMaterial":
      fakerTempValue = faker.commerce.productMaterial();
      break;
    case "productName":
      fakerTempValue = faker.commerce.productName();
      break;
    case "bs":
      fakerTempValue = faker.company.bs();
      break;
    case "bsAdjective":
      fakerTempValue = faker.company.bsAdjective();
      break;
    case "bsBuzz":
      fakerTempValue = faker.company.bsBuzz();
      break;
    case "bsNoun":
      fakerTempValue = faker.company.bsNoun();
      break;
    case "catchPhrase":
      fakerTempValue = faker.company.catchPhrase();
      break;
    case "catchPhraseAdjective":
      fakerTempValue = faker.company.catchPhraseAdjective();
      break;
    case "catchPhraseDescriptor":
      fakerTempValue = faker.company.catchPhraseDescriptor();
      break;
    case "catchPhraseNoun":
      fakerTempValue = faker.company.catchPhraseNoun();
      break;
    case "companyName":
      fakerTempValue = faker.company.companyName();
      break;
    case "companySuffix":
      fakerTempValue = faker.company.companySuffix();
      break;
    case "name":
      fakerTempValue = faker.company.name();
      break;
    case "suffixes":
      fakerTempValue = faker.company.suffixes().join(" ");
      break;
    case "collation":
      fakerTempValue = faker.database.collation();
      break;
    case "column":
      fakerTempValue = faker.database.column();
      break;
    case "engine":
      fakerTempValue = faker.database.engine();
      break;
    case "mongodbObjectId":
      fakerTempValue = faker.database.mongodbObjectId();
      break;
    case "type":
      fakerTempValue = faker.database.type();
      break;
    case "array":
      fakerTempValue = faker.datatype.array().join(" ");

      break;
    case "bigInt":
      fakerTempValue = String(faker.datatype.bigInt());
      break;
    case "boolean":
      fakerTempValue = String(faker.datatype.boolean());
      break;
    case "datetime":
      fakerTempValue = faker.datatype.datetime().toDateString();
      break;
    case "float":
      fakerTempValue = String(faker.datatype.float());
      break;
    case "hexadecimal":
      fakerTempValue = faker.datatype.hexadecimal();
      break;
    case "json":
      fakerTempValue = faker.datatype.json();
      break;
    case "number":
      fakerTempValue = String(faker.datatype.number());
      break;
    case "string":
      fakerTempValue = faker.datatype.string();
      break;
    case "uuid":
      fakerTempValue = faker.datatype.uuid();
      break;
    case "birthdate":
      fakerTempValue = faker.date.birthdate().toDateString();
      break;
    case "future":
      fakerTempValue = faker.date.future().toDateString();

      break;
    case "month":
      fakerTempValue = faker.date.month();
      break;
    case "past":
      fakerTempValue = faker.date.past().toDateString();

      break;
    case "recent":
      fakerTempValue = faker.date.recent().toDateString();

      break;
    case "soon":
      fakerTempValue = faker.date.soon().toDateString();

      break;
    case "weekday":
      fakerTempValue = faker.date.weekday();
      break;
    case "account":
      fakerTempValue = faker.finance.account();
      break;
    case "accountName":
      fakerTempValue = faker.finance.accountName();
      break;
    case "amount":
      fakerTempValue = faker.finance.amount();
      break;
    case "bic":
      fakerTempValue = faker.finance.bic();
      break;
    case "bitcoinAddress":
      fakerTempValue = faker.finance.bitcoinAddress();
      break;
    case "creditCardCVV":
      fakerTempValue = faker.finance.creditCardCVV();
      break;
    case "creditCardIssuer":
      fakerTempValue = faker.finance.creditCardIssuer();
      break;
    case "creditCardNumber":
      fakerTempValue = faker.finance.creditCardNumber();
      break;
    case "currencyCode":
      fakerTempValue = faker.finance.currencyCode();
      break;
    case "currencyName":
      fakerTempValue = faker.finance.currencyName();
      break;
    case "currencySymbol":
      fakerTempValue = faker.finance.currencySymbol();
      break;
    case "ethereumAddress":
      fakerTempValue = faker.finance.ethereumAddress();
      break;
    case "iban":
      fakerTempValue = faker.finance.iban();
      break;
    case "litecoinAddress":
      fakerTempValue = faker.finance.litecoinAddress();
      break;
    case "mask":
      fakerTempValue = faker.finance.mask();
      break;
    case "pin":
      fakerTempValue = faker.finance.pin();
      break;
    case "routingNumber":
      fakerTempValue = faker.finance.routingNumber();
      break;
    case "transactionDescription":
      fakerTempValue = faker.finance.transactionDescription();
      break;
    case "transactionType":
      fakerTempValue = faker.finance.transactionType();
      break;
    case "branch":
      fakerTempValue = faker.git.branch();
      break;
    case "commitEntry":
      fakerTempValue = faker.git.commitEntry();
      break;
    case "commitMessage":
      fakerTempValue = faker.git.commitMessage();
      break;
    case "commitSha":
      fakerTempValue = faker.git.commitSha();
      break;
    case "shortSha":
      fakerTempValue = faker.git.shortSha();
      break;
    case "abbreviation":
      fakerTempValue = faker.hacker.abbreviation();
      break;
    case "adjective":
      fakerTempValue = faker.hacker.adjective();
      break;
    case "ingverb":
      fakerTempValue = faker.hacker.ingverb();
      break;
    case "noun":
      fakerTempValue = faker.hacker.noun();
      break;
    case "phrase":
      fakerTempValue = faker.hacker.phrase();
      break;
    case "verb":
      fakerTempValue = faker.hacker.verb();
      break;
    case "arrayElement":
      fakerTempValue = faker.helpers.arrayElement();
      break;
    case "arrayElements":
      fakerTempValue = faker.helpers.arrayElements().join(" ");
      break;
    case "regexpStyleStringParse":
      fakerTempValue = faker.helpers.regexpStyleStringParse();
      break;
    case "repeatString":
      fakerTempValue = faker.helpers.repeatString();
      break;
    case "replaceCreditCardSymbols":
      fakerTempValue = faker.helpers.replaceCreditCardSymbols();
      break;
    case "replaceSymbols":
      fakerTempValue = faker.helpers.replaceSymbols();
      break;
    case "replaceSymbolWithNumber":
      fakerTempValue = faker.helpers.replaceSymbolWithNumber();
      break;
    case "shuffle":
      fakerTempValue = faker.helpers.shuffle().join(" ");
      break;
    case "slugify":
      fakerTempValue = faker.helpers.slugify();
      break;
    case "abstract":
      fakerTempValue = faker.image.abstract();
      break;
    case "animals":
      fakerTempValue = faker.image.animals();
      break;
    case "avatar":
      fakerTempValue = faker.image.avatar();
      break;
    case "business":
      fakerTempValue = faker.image.business();
      break;
    case "cats":
      fakerTempValue = faker.image.cats();
      break;
    case "city":
      fakerTempValue = faker.image.city();
      break;
    case "dataUri":
      fakerTempValue = faker.image.dataUri();
      break;
    case "fashion":
      fakerTempValue = faker.image.fashion();
      break;
    case "food":
      fakerTempValue = faker.image.food();
      break;
    case "image":
      fakerTempValue = faker.image.image();
      break;
    case "imageUrl":
      fakerTempValue = faker.image.imageUrl();
      break;
    case "nature":
      fakerTempValue = faker.image.nature();
      break;
    case "nightlife":
      fakerTempValue = faker.image.nightlife();
      break;
    case "people":
      fakerTempValue = faker.image.people();
      break;
    case "sports":
      fakerTempValue = faker.image.sports();
      break;
    case "technics":
      fakerTempValue = faker.image.technics();
      break;
    case "transport":
      fakerTempValue = faker.image.transport();
      break;
    case "avatar":
      fakerTempValue = faker.internet.avatar();
      break;
    case "color":
      fakerTempValue = faker.internet.color();
      break;
    case "domainName":
      fakerTempValue = faker.internet.domainName();
      break;
    case "domainSuffix":
      fakerTempValue = faker.internet.domainSuffix();
      break;
    case "domainWord":
      fakerTempValue = faker.internet.domainWord();
      break;
    case "email":
      fakerTempValue = faker.internet.email();
      break;
    case "emoji":
      fakerTempValue = faker.internet.emoji();
      break;
    case "exampleEmail":
      fakerTempValue = faker.internet.exampleEmail();
      break;
    case "httpMethod":
      fakerTempValue = faker.internet.httpMethod();
      break;
    case "httpStatusCode":
      fakerTempValue = String(faker.internet.httpStatusCode());
      break;
    case "ip":
      fakerTempValue = faker.internet.ip();
      break;
    case "ipv4":
      fakerTempValue = faker.internet.ipv4();
      break;
    case "ipv6":
      fakerTempValue = faker.internet.ipv6();
      break;
    case "mac":
      fakerTempValue = faker.internet.mac();
      break;
    case "password":
      fakerTempValue = faker.internet.password();
      break;
    case "port":
      fakerTempValue = String(faker.internet.port());
      break;
    case "protocol":
      fakerTempValue = faker.internet.protocol();
      break;
    case "url":
      fakerTempValue = faker.internet.url();
      break;
    case "userAgent":
      fakerTempValue = faker.internet.userAgent();
      break;
    case "userName":
      fakerTempValue = faker.internet.userName();
      break;
    case "lines":
      fakerTempValue = faker.lorem.lines();
      break;
    case "paragraph":
      fakerTempValue = faker.lorem.paragraph();
      break;
    case "paragraphs":
      fakerTempValue = faker.lorem.paragraphs();
      break;
    case "sentence":
      fakerTempValue = faker.lorem.sentence();
      break;
    case "sentences":
      fakerTempValue = faker.lorem.sentences();
      break;
    case "slug":
      fakerTempValue = faker.lorem.slug();
      break;
    case "text":
      fakerTempValue = faker.lorem.text();
      break;
    case "word":
      fakerTempValue = faker.lorem.word();
      break;
    case "words":
      fakerTempValue = faker.lorem.words();
      break;
    case "rand":
      fakerTempValue = String(faker.mersenne.rand());
      break;

    case "genre":
      fakerTempValue = faker.music.genre();
      break;
    case "songName":
      fakerTempValue = faker.music.songName();
      break;
    case "findName":
      fakerTempValue = faker.name.findName();
      break;
    case "firstName":
      fakerTempValue = faker.name.firstName();
      break;
    case "fullName":
      fakerTempValue = faker.name.fullName();
      break;
    case "gender":
      fakerTempValue = faker.name.gender();
      break;
    case "jobArea":
      fakerTempValue = faker.name.jobArea();
      break;
    case "jobDescriptor":
      fakerTempValue = faker.name.jobDescriptor();
      break;
    case "jobTitle":
      fakerTempValue = faker.name.jobTitle();
      break;
    case "jobType":
      fakerTempValue = faker.name.jobType();
      break;
    case "lastName":
      fakerTempValue = faker.name.lastName();
      break;
    case "middleName":
      fakerTempValue = faker.name.middleName();
      break;
    case "prefix":
      fakerTempValue = faker.name.prefix();
      break;
    case "sex":
      fakerTempValue = faker.name.sex();
      break;
    case "sexType":
      fakerTempValue = faker.name.sexType();
      break;
    case "suffix":
      fakerTempValue = faker.name.suffix();
      break;
    case "imei":
      fakerTempValue = faker.phone.imei();
      break;
    case "number":
      fakerTempValue = faker.phone.number();
      break;
    case "phoneFormats":
      fakerTempValue = faker.phone.phoneFormats();
      break;
    case "phoneNumber":
      fakerTempValue = faker.phone.phoneNumber();
      break;
    case "phoneNumberFormat":
      fakerTempValue = faker.phone.phoneNumberFormat();
      break;
    case "alpha":
      fakerTempValue = faker.random.alpha();
      break;
    case "alphaNumeric":
      fakerTempValue = faker.random.alphaNumeric();
      break;
    case "locale":
      fakerTempValue = faker.random.locale();
      break;
    case "numeric":
      fakerTempValue = faker.random.numeric();
      break;
    case "word":
      fakerTempValue = faker.random.word();
      break;
    case "words":
      fakerTempValue = faker.random.words();
      break;
    case "chemicalElement":
      fakerTempValue = String(faker.science.chemicalElement());
      break;
    case "unit":
      fakerTempValue = String(faker.science.unit());
      break;
    case "commonFileExt":
      fakerTempValue = faker.system.commonFileExt();
      break;
    case "commonFileName":
      fakerTempValue = faker.system.commonFileName();
      break;
    case "commonFileType":
      fakerTempValue = faker.system.commonFileType();
      break;
    case "cron":
      fakerTempValue = faker.system.cron();
      break;
    case "directoryPath":
      fakerTempValue = faker.system.directoryPath();
      break;
    case "fileExt":
      fakerTempValue = faker.system.fileExt();
      break;
    case "fileName":
      fakerTempValue = faker.system.fileName();
      break;
    case "filePath":
      fakerTempValue = faker.system.filePath();
      break;
    case "fileType":
      fakerTempValue = faker.system.fileType();
      break;
    case "mimeType":
      fakerTempValue = faker.system.mimeType();
      break;
    case "networkInterface":
      fakerTempValue = faker.system.networkInterface();
      break;
    case "semver":
      fakerTempValue = faker.system.semver();
      break;
    case "bicycle":
      fakerTempValue = faker.vehicle.bicycle();
      break;
    case "color":
      fakerTempValue = faker.vehicle.color();
      break;
    case "fuel":
      fakerTempValue = faker.vehicle.fuel();
      break;
    case "manufacturer":
      fakerTempValue = faker.vehicle.manufacturer();
      break;
    case "model":
      fakerTempValue = faker.vehicle.model();
      break;
    case "type":
      fakerTempValue = faker.vehicle.type();
      break;
    case "vehicle":
      fakerTempValue = faker.vehicle.vehicle();
      break;
    case "vin":
      fakerTempValue = faker.vehicle.vin();
      break;
    case "adjective":
      fakerTempValue = faker.word.adjective();
      break;
    case "adverb":
      fakerTempValue = faker.word.adverb();
      break;
    case "conjunction":
      fakerTempValue = faker.word.conjunction();
      break;
    case "interjection":
      fakerTempValue = faker.word.interjection();
      break;
    case "preposition":
      fakerTempValue = faker.word.preposition();
      break;
  }
  return fakerTempValue;
}
