import {createContext} from 'react';
import { User } from '../interfaces';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import firebaseApp from "./firebaseApp";


const fbAuth = getAuth(firebaseApp);

export const AuthContext = createContext({user: null, role: ""});

export async function loginWithEmail(form: User) {
    const {email, password} = form;
    return await signInWithEmailAndPassword(fbAuth, email, password);
}

export async function signOut() {
    await   fbAuth.signOut();

}

export const currentUser = fbAuth.currentUser;


export default fbAuth;
