import React, { FC } from 'react'
import { Redirect, Route, RouteProps } from 'react-router';

const PrivateRoute: FC<PrivateRouteProps> = ({authenticated, ...routeProps}) => {
  if (authenticated) {
    return <Route {...routeProps} />;
  }
  
  return <Redirect to="/login" />;
}

type PrivateRouteProps = {
  authenticated: boolean;
} & RouteProps;

export default PrivateRoute
