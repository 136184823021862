import React, {useEffect, useState} from 'react';
import './App.css';
import Routes from "./Router/Routes";
import fbAuth, {AuthContext} from './services/fbAuth';
import {BrowserRouter as Router} from 'react-router-dom';


const App: React.FC = () => {
  const [user, setUser] = useState<any | null>(null);
  const [role, setRole] = useState<string>("");

  const [pending, setPending] = useState(true);
  useEffect(() => {
    fbAuth.onAuthStateChanged((currentUser) => {


      // currentUser?.getIdTokenResult().then(idTokenResult => {
      //
      //   const userRole = idTokenResult.claims.role || 0;
      //
      //   let currentRole = "";
      //   if (userRole > 0 && userRole <= 5) {
      //     currentRole = "admin";
      //   } else if (userRole > 5 && userRole <= 15) {
      //     currentRole = "editor"
      //   } else if (userRole >= 15 && userRole <= 20) {
      //     currentRole = "teacher"
      //   } else {
      //     currentRole = ""
      //   }
      //   setRole(currentRole)
      //
      //
      //
      // })

      setUser(currentUser)
      setPending(false);


    });
  }, []);
  if (pending) {
    return <>Please wait...</>
  }
  return (
    <Router>
      <AuthContext.Provider value={{user, role}}>
        <Routes/>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
