import React from "react";

import fbAuth from "../services/fbAuth";
import { Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/Login";
import Home from "../pages/Home";
import ClassReportByUid from "../pages/CommonPages/ClassReportByUid";
import LcBookings from "../pages/CommonPages/LcBookings";
import IdGeneratorPage from "../pages/id-generator";
import FakeDataGenerator from "../pages/fake-data-generator";
import GenerateCsData from "../pages/generate-cs-data";
import CorporateCourseList from "../pages/CommonPages/corporate-course-list";
import WaitingListReport from "../pages/CommonPages/waiting-list-report";

interface Props {}

const Routes = () => {
  const user = fbAuth.currentUser;
  const authenticated = Boolean(user);

  return (
    <Switch>
      {/* admin */}

      {/* authentication */}
      <PublicRoute
        exact
        path='/login'
        component={Login}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path='/'
        component={Home}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path='/class-reports-by-uid'
        component={ClassReportByUid}
        authenticated={authenticated}
      />
        <PrivateRoute
        exact
        path='/lcBookings'
        component={LcBookings}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path='/corporate-course-list'
        component={CorporateCourseList}
        authenticated={authenticated}
      />
      
      <PrivateRoute
        exact
        path='/waiting-list-report'
        component={WaitingListReport}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path='/student-report'
        component={ClassReportByUid}
        authenticated={authenticated}
      />
      <PrivateRoute
        exact
        path='/generate-cs-data'
        component={GenerateCsData}
        authenticated={authenticated}
      />
      {/*pages public*/}
      <PublicRoute
        exact
        path='/fake-data-generator'
        component={FakeDataGenerator}
        authenticated={authenticated}
      />
      <PublicRoute
        exact
        path='/id-generator'
        component={IdGeneratorPage}
        authenticated={authenticated}
      />
      {/*<ComingSoon/>*/}
    </Switch>
  );
};

export default Routes;
