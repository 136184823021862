import React, { useState } from "react";
import AppContainer from "../containers/AppContainer";
import { v4 } from "uuid";
import { nanoid } from "nanoid";

const IdGeneratorPage = () => {
  const [idTypeState, setIdTypeState] = useState("nanoid");
  const [repeatCountState, setRepeatCountState] = useState(1);
  const [idCollectionArray, setIdCollectionArray] = useState<string[]>([]);

  function handleGenerate() {
    const tempIdArray: string[] = [];

    for (let i = 0; i < repeatCountState; i++) {
      const tempId = idTypeState === "nanoid" ? nanoid() : v4();

      tempIdArray.push(tempId);
    }
    setIdCollectionArray([...tempIdArray]);
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container ">
        <div className="row card">
          <div className="column">
            <h2>UUID Generator</h2>
            <p>UUID: example: b9351281-7883-4209-bda1-6b791ee2ef55</p>
            <p>NANO ID: example: grLyyGTu7NxB1JIKMjUlE</p>
          </div>
        </div>
        <div className="row card">
          <div className="column">
            <input
              type="number"
              placeholder={"How Many? "}
              onChange={(e) => setRepeatCountState(Number(e.target.value))}
            />
          </div>
          <div className="column">
            <select
              name="idType"
              id="idType"
              placeholder={"Type"}
              onChange={(e) => setIdTypeState(e.target.value)}
            >
              <option value="nanoid">NANO ID</option>
              <option value="uuid">UUID</option>
            </select>
          </div>

          <div className="column">
            <button className="button button-clear" onClick={handleGenerate}>Generate</button>
          </div>
        </div>
        <div className="row card">
          <div className="column">
            <textarea
              style={{ fontSize: "2rem" }}
              name="resultAll"
              id="resultAll"
              rows={50}
              value={idCollectionArray.join("\n")}
            />
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default IdGeneratorPage;
