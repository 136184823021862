import React from "react";
import AppContainer from "../containers/AppContainer";

const GenerateCsData = () => {
  const parentList = ["TimeStamp"];
  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        <div className="row">
          <div className="column">
            <h2>Generate CS Data</h2>
          </div>
        </div>
        <div className="row">
          <div className="column"></div>
          <div className="column column-75"></div>
        </div>
      </div>
    </AppContainer>
  );
};

export default GenerateCsData;
