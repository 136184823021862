import React, { useEffect, useState } from "react";
import AppContainer from "../../containers/AppContainer";
import { child, get, getDatabase, ref, set } from "firebase/database";
import Modal from "../../components/ModalPopups/AddSectionModal";

const slugify = require("slugify");

export async function getCorporateCoursesList() {
  const dbRef = ref(getDatabase());

  const childRef = `sepContent/corp-courses/courseList/english`;
  const snapshot = await get(child(dbRef, childRef));
  if (snapshot.exists()) {
    return snapshot.val();
  }
}

const CorporateCourseList = () => {
  const [courseListState, setCourseListState] = useState<any>([]);
  const [currentCourse, setCurrentCourse] = useState<any>({});
  const [slug, setSlug] = useState<string>("");
  const [courseTitleState, setCourseTitleState] = useState<string>("");
  const [sectionListArray, setSectionListArray] = useState<any[]>([]);
  const [repeatCountState, setRepeatCountState] = useState(10);
  const [idCollectionArray, setIdCollectionArray] = useState<string[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCourseModalOpen, setIsCourseModalOpen] = useState(false);
  const [isSectionModalOpen, setSectionModalOpen] = useState(false);

  const [refreshData, setRefreshData] = useState(false);
  useEffect(() => {
    const call = async () => {
      const data = await getCorporateCoursesList();
      setCourseListState(Object.values(data));
    };

    call();
  }, [refreshData]);

  function handleCourseListClick(courseId) {
    const currentCourseLocal = courseListState.find(
      (item) => item.courseId === courseId
    );
    setCurrentCourse(currentCourseLocal);
    handleCourseModalOpen();
  }

  function handleFieldsChange(e) {
    setCurrentCourse({
      ...currentCourse,
      [e.target.name]: e.target.value,
    });
  }

  function handleAddNewInput(e) {
    e.preventDefault();
    const value = e.target.value as string;
    // remove special characters
    if (value === "") return;
    setSlug(
      slugify(
        value
          .replace(/[^\w\s]/gi, "")
          .toLowerCase()
          .trim()
      )
    );
    setCourseTitleState(value);
  }

  async function addNewCorporateCourse(slug, courseTitle) {
    if (!slug || !courseTitle) {
      alert("Please write course title");
      return;
    }
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/courseList/english/${slug}`;
    // check if slug exists
    const snapshot = await get(child(dbRef, childRef));
    //
    try {
      if (snapshot.exists()) {
        alert("Course ID already exists");
        setSlug("");
        setCourseTitleState("");
        return;
      }
    } catch (error) {
      console.log(error);
    }

    set(child(dbRef, childRef), {
      courseId: slug,
      courseTitle: courseTitle,
    })
      .then((res) => {
        alert("New Course Added");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleUpdateButtonClick() {
    setRefreshData(!refreshData);
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/courseList/english/${currentCourse.courseId}`;
    set(child(dbRef, childRef), currentCourse)
      .then((res) => {
        // alert("Course Updated");
        // window.location.reload();
        setIsCourseModalOpen(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleModalOpen = () => {
    setCurrentCourse(null);
    setIsModalOpen(true);
  };
  const handleCourseModalOpen = () => {
    setIsCourseModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsCourseModalOpen(false);
    setSectionModalOpen(false);
  };

  function handleViewSectionsClick(courseId) {
    const currentCourseLocal = courseListState.find(
      (item) => item.courseId === courseId
    );
    setCurrentCourse(currentCourseLocal);
    // get all sections
    const dbRef = ref(getDatabase());
    const childRef = `sepContent/corp-courses/sectionsList/english/${courseId}`;
    get(child(dbRef, childRef)).then((snapshot) => {
      if (snapshot.exists()) {
        const data = snapshot.val();
        const idCollectionArrayLocal = Object.keys(data);
        setIdCollectionArray(idCollectionArrayLocal);
        setSectionListArray(Object.values(data));
      }
    });

    setSectionModalOpen(true);
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        <div className="row">
          <div className="column">
            <b>Corporate Courses</b>
            <button onClick={handleCourseModalOpen} className={"button"}>
              Add New Course
            </button>
          </div>
        </div>

        <div className="row">
          <div className="column">
            <ul style={{ display: "flex", flexDirection: "column" }}>
              {courseListState.length > 0 &&
                courseListState.map((item) => (
                  <div className={"row card"} key={item.courseId}>
                    <div
                      className={"column"}
                      style={{ cursor: "pointer" }}
                      onClick={(e) => handleCourseListClick(item.courseId)}
                    >
                      {item.courseTitle}
                    </div>
                    <div className="column">
                      <button
                        className={"button button-clear"}
                        onClick={() => handleViewSectionsClick(item.courseId)}
                      >
                        View Sections
                      </button>
                      <button className={"button button-clear"}>
                        Add New Section
                      </button>
                      <button className={"button button-clear"}>
                        Edit Course
                      </button>
                      <button className={"button button-clear"}>
                        Delete Course
                      </button>
                    </div>
                  </div>
                ))}
            </ul>

            <div className="row">
              <div className="column"></div>
            </div>
          </div>
        </div>
        <div className={"row"}>
          <div className={"column"}>
            {/*Show Modal Course*/}
            <div className="row ">
              <Modal isOpen={isCourseModalOpen} onClose={handleModalClose}>
                <h5>
                  Add/Edit Course [<small>{currentCourse.courseId}</small>]
                </h5>
                {currentCourse ? (
                  <div className={"column"}>
                    <input
                      type="text"
                      value={currentCourse.courseTitle}
                      name={"courseTitle"}
                      onChange={(e) => handleFieldsChange(e)}
                    />
                    <div className="column"></div>
                  </div>
                ) : null}
                <button onClick={handleUpdateButtonClick}> Update</button>{" "}
                <button onClick={handleUpdateButtonClick}> Delete</button>
                <button onClick={handleModalClose}>Close</button>
              </Modal>
            </div>
            {/*  Show Modal Course End */}

            {/* Show Sections*/}
            <div className="row">
              <Modal isOpen={isSectionModalOpen} onClose={handleModalClose}>
                <div className="row">
                  <div className="column text-center">
                    {sectionListArray.length > 0 &&
                      sectionListArray.map((item) => (
                        <div className="row" key={item.sectionId}>
                          <div className="column">
                            <h5>{item.sectionTitle}</h5>
                          </div>
                          <div className="column ">
                            <button className={"button button-clear"}>
                              View Modules
                            </button>
                          </div>
                          <div className="column ">
                            <button className={"button button-clear"}>
                              Add New Module
                            </button>
                          </div>
                          <div className="column ">
                            <button className={"button button-clear"}>
                              Edit Section
                            </button>
                          </div>
                          <div className="column ">
                            <button className={"button button-clear"}>
                              Delete Section
                            </button>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="row">
                  <div className="column text-center">
                    <button onClick={handleModalOpen}>Add Sections</button>
                    <button onClick={handleModalClose}>Close</button>
                  </div>
                </div>
              </Modal>
            </div>
            {/* Show Sections*/}

            {/* Show Modal */}
            <div className="row ">
              <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <h3>Add Section</h3>
                <input type="text" name={"sectionId"} />
                <input type="text" name={"sectionTitle"} />
                <input type="text" name={"sectionDescription"} />
                <input type="text" name={"sectionDuration"} />
                <input type="text" name={"sectionVideoUrl"} />

                <button>Save</button>
                <button onClick={handleModalClose}>Close</button>
              </Modal>
            </div>
            {/*  Show modal*/}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default CorporateCourseList;
