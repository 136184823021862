import React, { useEffect, useState } from "react";
import AppContainer from "../containers/AppContainer";
import { fakeDataParentList } from "../constants/fake.contstants";
import { getSwitchValue } from "../methods/FakeDataMethods";

const FakeDataGenerator = () => {
  const [parentSelected, setParentSelected] = useState("");
  const [childSelected, setChildSelected] = useState("");
  const [childDropdownOption, setChildDropDownOptions] = useState<string[]>([]);
  const [repeatCountState, setRepeatCountState] = useState(10);
  const [idCollectionArray, setIdCollectionArray] = useState<string[]>([]);

  useEffect(() => {
    const objToArray = Object.entries(fakeDataParentList);
    console.log("objToArray", objToArray);
    const FilteredArray = objToArray.find(([item]) => item === parentSelected);
    if (FilteredArray) setChildDropDownOptions(FilteredArray[1]);
    handleGenerate();
  }, [childSelected, parentSelected, repeatCountState]);

  function handleGenerate() {
    const tempIdArray: string[] = [];
    console.log("childSelected", childSelected);
    for (let i = 0; i < repeatCountState; i++) {
      tempIdArray.push(getSwitchValue(childSelected, parentSelected));
    }
    setIdCollectionArray([...tempIdArray]);
  }

  function handleParentDropdown(value: string) {
    setParentSelected(value);
  }

  function handleChildDropdown(value: string) {
    setChildSelected(value);
  }

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container paper">
        <div className="row">
          <div className="column">
            <h2>Fake Generator</h2>
            {JSON.stringify({
              childSelected,
              parentSelected,
              repeatCountState,
            })}
          </div>
        </div>
        <div className="row">
          <div className="column">
            <input
              type="number"
              placeholder={"How Many? "}
              value={repeatCountState}
              onChange={(e) => setRepeatCountState(Number(e.target.value))}
            />
          </div>
          <div className="column">
            <select
              name="idType"
              id="idType"
              placeholder={"Type"}
              onChange={(e) => handleParentDropdown(e.target.value)}
            >
              <option value={""}>Please Select</option>

              {Object.keys(fakeDataParentList).map((item) => (
                <option value={item}>{item}</option>
              ))}
            </select>
          </div>
          <div className="column">
            <button className="button button-clear" onClick={handleGenerate}>
              Generate
            </button>
          </div>
        </div>
        <div className="row">
          <div
            className="column"
            style={{ height: "600px", overflow: "scroll" }}
          >
            {/*<select*/}
            {/*  name="childDropDown"*/}
            {/*  id="childDropDown"*/}
            {/*  placeholder={"childDropDown"}*/}
            {/*  onChange={(e) => handleChildDropdown(e.target.value)}*/}
            {/*>*/}
            {/*  <option value="">Please Select</option>*/}
            {/*  {childDropdownOption.map((item) => (*/}
            {/*    <option value={item}>{item}</option>*/}
            {/*  ))}*/}
            {/*</select>*/}

            {childDropdownOption.map((item) => (
              <button
                className="button button-clear"
                value={item}
                onClick={(e) => handleChildDropdown(item)}
              >
                {item}
              </button>
            ))}
          </div>

          <div className="column column-75">
            <textarea
              style={{ fontSize: "2rem", height: "500px" }}
              name="resultAll"
              id="resultAll"
              rows={100}
              value={idCollectionArray.join("\n")}
            />
            {/*{idCollectionArray.map((item) => (*/}
            {/*  <input type={"text"} value={item} />*/}
            {/*))}*/}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default FakeDataGenerator;
