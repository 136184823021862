import React, {useEffect, useRef, useState} from 'react';
import AppContainer from "../../containers/AppContainer";
import { getWaitingListReport} from "../../services/firebaseNodes";
import { WaitingListItemInterface} from "../../interfaces/common";
import moment from "moment";

import { CSVLink, CSVDownload } from "react-csv";

const WaitingListReport = () => {

  const textAreaRef = useRef();
  const [emailList, setEmailList] = useState("");
  const [startDateTsState, setStartDateTsState] = useState<Date>(new Date());
  const [endDateTsState, setEndDateTsState] = useState<Date>(new Date());
  const [waitingListStateData, setWaitingListStateData] = useState<WaitingListItemInterface[]>([]);
  const [waitingListStateDataTemp, setWaitingListStateDataTemp] = useState<WaitingListItemInterface[]>([]);

  const [errorMsg, setErrorMsg] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [lcCount, setLcCount] = useState({});
  const [csvData, setCsvData] = useState<any>([]);

  async function handleClick() {
    setErrorMsg("")
    const counts = {}
    const startDate = new Date(startDateTsState).getTime();
    const endDate = new Date(endDateTsState).getTime();
    const getWaitingListJson = await getWaitingListReport(startDate, endDate);
    if (getWaitingListJson.exists()) {

      const waitingListDataArray: WaitingListItemInterface[] = [];
      const waitingListDataLcList: string[] = []
      getWaitingListJson.forEach(waitingListItem => {
        waitingListDataArray.push(waitingListItem.val());
        waitingListDataLcList.push(waitingListItem.val().lpId)
      });
      setTotalCount(getWaitingListJson.size)
      setWaitingListStateData(waitingListDataArray);

      waitingListDataLcList.forEach((item: string) => {
        console.log(item)
        // @ts-ignore
        counts[item] = (counts[item] || 0) + 1;
      })

      setLcCount(counts)


      console.log(counts)


    } else {
      setErrorMsg("No data found");
    }
  }

  // function handleOnChange(e: any) {
  //   if (e.target.value) {
  //     setEmailList(e.target.value.split('\n'));
  //     console.log(emailList);

  //   }
  // }

  useEffect(() => {
    const csvDataFn = Object.values(waitingListStateDataTemp).map(
      (item: any) => {
        return [
          item.date,
          item.email,
          item.fullName,
          item.lcCategoryId,
          item.lcId,
          item.lpId,
          item.phone,
         item.teacherEmail,
          item.timeStamp,
          item.uid,
        
        ];
      }
    );
  
    const newCsvData = [
      [ "date",
      "email",
      "fullName",
      "lcCategoryId",
      "lcId",
      "lpId",
      "phone",
     "teacherEmail",
      "timeStamp",
      "uid"],
      ...csvDataFn,
    ];
  
    setCsvData(newCsvData);
    console.log(newCsvData);
  }, [waitingListStateDataTemp])
  

  function handleLpListItemClick(lpId: string) {
    const filteredData = waitingListStateData.filter((item) => {
      return item.lpId === lpId
    })
    setWaitingListStateDataTemp(filteredData)

    
    
  }




  return (
    <AppContainer headerTitle={"Class Report"}>
      <div className="container ">
        <div className="paper">

       
        <div className="row ">

          <div className="column">

            <input type={"date"} onChange={(e: any) => setStartDateTsState(e.target.value)}/>
          </div>
          <div className="column">

            <input type={"date"} onChange={(e: any) => setEndDateTsState(e.target.value)}/>
          </div>
          <div className="column">

            <button className="button button-outline" onClick={handleClick}>Submit</button>
          </div>

        </div>
        </div>
        {errorMsg !== "" ? errorMsg : ""}
        <div className="row">


          <div className="column">
            <h3 className='text-center'>Form Submission Per Class</h3>
            <div className='table-container card'>

            <table className={"bg-light"}>
              <th>Live Class Title</th>
              <th>Waiting Count</th>
              {

                Object.entries(lcCount).map((value) =>
                  <tr key={value[0]}>
                    <td><button className='button button-clear' onClick={()=>handleLpListItemClick(value[0])}>{String(value[0])}</button> </td>

                    <td>{String(value[1])}</td>


                  </tr>
                )
              }

            </table>
            </div>
          </div>

        </div>
        <hr/>
        
          <h3 className='text-center'>Form Sumission Details</h3>
          <div className="row">
            <div className="column text-center">
            {waitingListStateDataTemp && waitingListStateDataTemp.length > 0 && (
              <CSVLink data={csvData}>Download</CSVLink>
            )}
            </div>
          </div>
        <div className='card'>
         
        <div className="row">
        <div className="column">
        

        <div className='table-container'>
          <table className={"bg-light"}>
            <thead>
            <tr>
              <th>Date</th>
              <th>Full Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Teacher Email</th>
              <th>Category ID</th>
              <th>Live Class Recurrence</th>
              <th>Live Class</th>
              <th>User Id</th>
              <th>Month</th>
            
            </tr>
            </thead>
            <tbody>
            {
              waitingListStateDataTemp.length > 0 ? waitingListStateDataTemp.map((item, index) =>
                <tr key={index}>
                  <td>{item.date}</td>
                  <td>{item.fullName}</td>
                  <td>{item.email}</td>
                  <td>{item.phone}</td>
                  <td>{item.teacherEmail}</td>
                  <td>{item.lcCategoryId}</td>
                  <td>{item.lcId}</td>
                  <td>{item.lpId}</td>
                  <td>{item.uid}</td>
                  <td>{item.timeStamp ? moment(item.timeStamp).format("MMM YYYY") : ""}</td>
              

                
                </tr>
              ) : null
            }

            </tbody>
          </table>
          </div>
          </div>
        </div>
        </div>
      
      </div>
    </AppContainer>
  );
}


export default WaitingListReport;
