import {db} from "./firebaseApp";
import {endAt, get, orderByChild, query, ref, startAt} from "firebase/database";

export async function getLcBookings(startDate: number, endDate: number) {
  const dbQuery = query(ref(db, 'lcBookings/english'), orderByChild('bookingTs'), startAt(startDate), endAt(endDate));
  return await get(dbQuery);
}


export async function getWaitingListReport(startDate: number, endDate: number) {
  const dbQuery = query(ref(db, 'liveClassWaitingList'), orderByChild('timeStamp'), startAt(startDate), endAt(endDate));
  return await get(dbQuery);
}




