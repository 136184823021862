export const fakeDataChildList = {
  AvailabilityCharger: ["Available", "Busy", "Offline", "Not Available"],
  Random_Power: ["On", "Off", "disable", "Offline"],
  EvChargerType: ["Free", "Paid", "Own"],
  Cities_India: [
    "Delhi",
    "Mumbai",
    "Kolkāta",
    "Bangalore",
    "Chennai",
    "Hyderābād",
    "Pune",
    "Ahmedabad",
    "Sūrat",
    "Lucknow",
    "Jaipur",
    "Cawnpore",
    "Mirzāpur",
    "Nāgpur",
    "Ghāziābād",
    "Indore",
    "Vadodara",
    "Vishākhapatnam",
    "Bhopāl",
    "Chinchvad",
    "Patna",
    "Ludhiāna",
    "Āgra",
    "Kalyān",
    "Madurai",
    "Jamshedpur",
    "Nāsik",
    "Farīdābād",
    "Aurangābād",
    "Rājkot",
    "Meerut",
    "Jabalpur",
    "Thāne",
    "Dhanbād",
    "Allahābād",
    "Vārānasi",
    "Srīnagar",
    "Amritsar",
    "Alīgarh",
    "Bhiwandi",
    "Gwalior",
    "Bhilai",
    "Hāora",
    "Rānchi",
    "Bezwāda",
    "Chandīgarh",
    "Mysore",
    "Raipur",
    "Kota",
    "Bareilly",
    "Jodhpur",
    "Coimbatore",
    "Dispur",
    "Guwāhāti",
    "Solāpur",
    "Trichinopoly",
    "Hubli",
    "Jalandhar",
    "Bhubaneshwar",
    "Bhayandar",
    "Morādābād",
    "Kolhāpur",
    "Thiruvananthapuram",
    "Sahāranpur",
    "Warangal",
    "Salem",
    "Mālegaon",
    "Kochi",
    "Gorakhpur",
    "Shimoga",
    "Tiruppūr",
    "Guntūr",
    "Raurkela",
    "Mangalore",
    "Nānded",
    "Cuttack",
    "Chānda",
    "Dehra Dūn",
    "Durgāpur",
    "Āsansol",
    "Bhāvnagar",
    "Amrāvati",
    "Nellore",
    "Ajmer",
    "Tinnevelly",
    "Bīkaner",
    "Agartala",
    "Ujjain",
    "Jhānsi",
    "Ulhāsnagar",
    "Davangere",
    "Jammu",
    "Belgaum",
    "Gulbarga",
    "Jāmnagar",
    "Dhūlia",
    "Gaya",
    "Jalgaon",
    "Kurnool",
    "Udaipur",
    "Bellary",
    "Sāngli",
    "Tuticorin",
    "Calicut",
    "Akola",
    "Bhāgalpur",
    "Sīkar",
    "Tumkūr",
    "Quilon",
    "Muzaffarnagar",
    "Bhīlwāra",
    "Nizāmābād",
    "Bhātpāra",
    "Kākināda",
    "Parbhani",
    "Pānihāti",
    "Lātūr",
    "Rohtak",
    "Rājapālaiyam",
    "Ahmadnagar",
    "Cuddapah",
    "Rājahmundry",
    "Alwar",
    "Muzaffarpur",
    "Bilāspur",
    "Mathura",
    "Kāmārhāti",
    "Patiāla",
    "Saugor",
    "Bijāpur",
    "Brahmapur",
    "Shāhjānpur",
    "Trichūr",
    "Barddhamān",
    "Kulti",
    "Sambalpur",
    "Purnea",
    "Hisar",
    "Fīrozābād",
    "Bīdar",
    "Rāmpur",
    "Shiliguri",
    "Bāli",
    "Pānīpat",
    "Karīmnagar",
    "Bhuj",
    "Ichalkaranji",
    "Tirupati",
    "Hospet",
    "Āīzawl",
    "Sannai",
    "Bārāsat",
    "Ratlām",
    "Handwāra",
    "Drug",
    "Imphāl",
    "Anantapur",
    "Etāwah",
    "Rāichūr",
    "Ongole",
    "Bharatpur",
    "Begusarai",
    "Sonīpat",
    "Rāmgundam",
    "Hāpur",
    "Uluberiya",
    "Porbandar",
    "Pāli",
    "Vizianagaram",
    "Puducherry",
    "Karnāl",
    "Nāgercoil",
    "Tanjore",
    "Sambhal",
    "Naihāti",
    "Secunderābād",
    "Kharagpur",
    "Dindigul",
    "Shimla",
    "Ingrāj Bāzār",
    "Ellore",
    "Puri",
    "Haldia",
    "Nandyāl",
    "Bulandshahr",
    "Chakradharpur",
    "Bhiwāni",
    "Gurgaon",
    "Burhānpur",
    "Khammam",
    "Madhyamgram",
    "Ghāndīnagar",
    "Baharampur",
    "Mahbūbnagar",
    "Mahesāna",
    "Ādoni",
    "Rāiganj",
    "Bhusāval",
    "Bahraigh",
    "Shrīrāmpur",
    "Tonk",
    "Sirsa",
    "Jaunpur",
    "Madanapalle",
    "Hugli",
    "Vellore",
    "Alleppey",
    "Cuddalore",
    "Deo",
    "Chīrāla",
    "Machilīpatnam",
    "Medinīpur",
    "Bāramūla",
    "Chandannagar",
    "Fatehpur",
    "Udipi",
    "Tenāli",
    "Sitalpur",
    "Conjeeveram",
    "Proddatūr",
    "Navsāri",
    "Godhra",
    "Budaun",
    "Chittoor",
    "Harīpur",
    "Saharsa",
    "Vidisha",
    "Pathānkot",
    "Nalgonda",
    "Dibrugarh",
    "Bālurghāt",
    "Krishnanagar",
    "Fyzābād",
    "Silchar",
    "Shāntipur",
    "Hindupur",
    "Erode",
    "Jāmuria",
    "Hābra",
    "Ambāla",
    "Mauli",
    "Kolār",
    "Shillong",
    "Bhīmavaram",
    "New Delhi",
    "Mandsaur",
    "Kumbakonam",
    "Tiruvannāmalai",
    "Chicacole",
    "Bānkura",
    "Mandya",
    "Hassan",
    "Yavatmāl",
    "Pīlibhīt",
    "Pālghāt",
    "Abohar",
    "Pālakollu",
    "Kānchrāpāra",
    "Port Blair",
    "Alīpur Duār",
    "Hāthras",
    "Guntakal",
    "Navadwīp",
    "Basīrhat",
    "Hālīsahar",
    "Rishra",
    "Dharmavaram",
    "Baidyabāti",
    "Darjeeling",
    "Sopur",
    "Gudivāda",
    "Adilābād",
    "Titāgarh",
    "Chittaurgarh",
    "Narasaraopet",
    "Dam Dam",
    "Vālpārai",
    "Osmānābād",
    "Champdani",
    "Bangaon",
    "Khardah",
    "Tādpatri",
    "Jalpāiguri",
    "Suriāpet",
    "Tādepallegūdem",
    "Bānsbāria",
    "Negapatam",
    "Bhadreswar",
    "Chilakalūrupet",
    "Kalyani",
    "Gangtok",
    "Kohīma",
    "Khambhāt",
    "Aurangābād",
    "Emmiganūr",
    "Rāyachoti",
    "Kāvali",
    "Mancherāl",
    "Kadiri",
    "Ootacamund",
    "Anakāpalle",
    "Sirsilla",
    "Kāmāreddipet",
    "Pāloncha",
    "Kottagūdem",
    "Tanuku",
    "Bodhan",
    "Karūr",
    "Mangalagiri",
    "Kairāna",
    "Mārkāpur",
    "Malaut",
    "Bāpatla",
    "Badvel",
    "Jorhāt",
    "Koratla",
    "Pulivendla",
    "Jaisalmer",
    "Tādepalle",
    "Armūr",
    "Jatani",
    "Gadwāl",
    "Nagari",
    "Wanparti",
    "Ponnūru",
    "Vinukonda",
    "Itānagar",
    "Tezpur",
    "Narasapur",
    "Kothāpet",
    "Mācherla",
    "Kandukūr",
    "Sāmalkot",
    "Bobbili",
    "Sattenapalle",
    "Vrindāvan",
    "Mandapeta",
    "Belampalli",
    "Bhīmunipatnam",
    "Nāndod",
    "Pithāpuram",
    "Punganūru",
    "Puttūr",
    "Jalor",
    "Palmaner",
    "Dholka",
    "Jaggayyapeta",
    "Tuni",
    "Amalāpuram",
    "Jagtiāl",
    "Vikārābād",
    "Venkatagiri",
    "Sihor",
    "Jangaon",
    "Mandamāri",
    "Metpalli",
    "Repalle",
    "Bhainsa",
    "Jasdan",
    "Jammalamadugu",
    "Rāmeswaram",
    "Addanki",
    "Nidadavole",
    "Bodupāl",
    "Rājgīr",
    "Rajaori",
    "Naini Tal",
    "Channarāyapatna",
    "Maihar",
    "Panaji",
    "Junnar",
    "Amudālavalasa",
    "Damān",
    "Kovvūr",
    "Solan",
    "Dwārka",
    "Pathanāmthitta",
    "Kodaikānal",
    "Udhampur",
    "Giddalūr",
    "Yellandu",
    "Shrīrangapattana",
    "Angamāli",
    "Umaria",
    "Fatehpur Sīkri",
    "Mangūr",
    "Pedana",
    "Uran",
    "Chimākurti",
    "Devarkonda",
    "Bandipura",
    "Silvassa",
    "Pāmidi",
    "Narasannapeta",
    "Jaynagar-Majilpur",
    "Khed Brahma",
    "Khajurāho",
    "Koilkuntla",
    "Diu",
    "Kulgam",
    "Gauripur",
    "Abu",
    "Curchorem",
    "Kavaratti",
    "Panchkula",
    "Kagaznāgār",
  ],
};
export const fakeDataParentList = {
  Address: [
    "buildingNumber",
    "cardinalDirection",
    "city",
    "cityName",
    "cityPrefix",
    "citySuffix",
    "country",
    "countryCode",
    "county",
    "direction",
    "latitude",
    "longitude",
    "nearbyGPSCoordinate",
    "ordinalDirection",
    "secondaryAddress",
    "state",
    "stateAbbr",
    "street",
    "streetAddress",
    "streetName",
    "streetPrefix",
    "streetSuffix",
    "timeZone",
    "zipCode",
    "zipCodeByState",
  ],
  Date: ["birthdate", "future", "month", "past", "recent", "soon", "weekday"],
  Lorem: [
    "lines",
    "paragraph",
    "paragraphs",
    "sentence",
    "sentences",
    "slug",
    "text",
    "word",
    "words",
  ],
  Animal: [
    "bear",
    "bird",
    "cat",
    "cetacean",
    "cow",
    "crocodilia",
    "dog",
    "fish",
    "horse",
    "insect",
    "lion",
    "rabbit",
    "rodent",
    "snake",
    "type",
  ],
  Fake: ["fake"],
  Finance: [
    "account",
    "accountName",
    "amount",
    "bic",
    "bitcoinAddress",
    "creditCardCVV",
    "creditCardIssuer",
    "creditCardNumber",
    "currencyCode",
    "currencyName",
    "currencySymbol",
    "ethereumAddress",
    "iban",
    "litecoinAddress",
    "mask",
    "pin",
    "routingNumber",
    "transactionDescription",
    "transactionType",
  ],
  Mersenne: ["rand", "seed", "seed_array"],
  Music: ["genre", "songName"],
  Name: [
    "findName",
    "firstName",
    "fullName",
    "gender",
    "jobArea",
    "jobDescriptor",
    "jobTitle",
    "jobType",
    "lastName",
    "middleName",
    "prefix",
    "sex",
    "sexType",
    "suffix",
  ],
  Git: ["branch", "commitEntry", "commitMessage", "commitSha", "shortSha"],
  Phone: ["imei", "number", "phoneFormats", "phoneNumber", "phoneNumberFormat"],
  Color: [
    "cmyk",
    "colorByCSSColorSpace",
    "cssSupportedFunction",
    "cssSupportedSpace",
    "hsl",
    "human",
    "hwb",
    "lab",
    "lch",
    "rgb",
    "space",
  ],
  Hacker: ["abbreviation", "adjective", "ingverb", "noun", "phrase", "verb"],
  Random: ["alpha", "alphaNumeric", "locale", "numeric", "word", "words"],
  Commerce: [
    "color",
    "department",
    "price",
    "product",
    "productAdjective",
    "productDescription",
    "productMaterial",
    "productName",
  ],
  Helpers: [
    "arrayElement",
    "arrayElements",
    "fake",
    "maybe",
    "mustache",
    "objectKey",
    "objectValue",
    "regexpStyleStringParse",
    "repeatString",
    "replaceCreditCardSymbols",
    "replaceSymbolWithNumber",
    "replaceSymbols",
    "shuffle",
    "slugify",
    "unique",
    "uniqueArray",
  ],
  Science: ["chemicalElement", "unit"],
  Company: [
    "bs",
    "bsAdjective",
    "bsBuzz",
    "bsNoun",
    "catchPhrase",
    "catchPhraseAdjective",
    "catchPhraseDescriptor",
    "catchPhraseNoun",
    "companyName",
    "companySuffix",
    "name",
    "suffixes",
  ],
  System: [
    "commonFileExt",
    "commonFileName",
    "commonFileType",
    "cron",
    "directoryPath",
    "fileExt",
    "fileName",
    "filePath",
    "fileType",
    "mimeType",
    "networkInterface",
    "semver",
  ],
  Image: [
    "abstract",
    "animals",
    "avatar",
    "business",
    "cats",
    "city",
    "dataUri",
    "fashion",
    "food",
    "image",
    "imageUrl",
    "nature",
    "nightlife",
    "people",
    "sports",
    "technics",
    "transport",
  ],
  Unique: ["unique"],
  Database: ["collation", "column", "engine", "mongodbObjectId", "type"],
  Vehicle: [
    "bicycle",
    "color",
    "fuel",
    "manufacturer",
    "model",
    "type",
    "vehicle",
    "vin",
  ],
  Datatype: [
    "array",
    "bigInt",
    "boolean",
    "datetime",
    "float",
    "hexadecimal",
    "json",
    "number",
    "string",
    "uuid",
  ],
  Internet: [
    "avatar",
    "color",
    "domainName",
    "domainSuffix",
    "domainWord",
    "email",
    "emoji",
    "exampleEmail",
    "httpMethod",
    "httpStatusCode",
    "ip",
    "ipv4",
    "ipv6",
    "mac",
    "password",
    "port",
    "protocol",
    "url",
    "userAgent",
    "userName",
  ],
  Word: [
    "adjective",
    "adverb",
    "conjunction",
    "interjection",
    "noun",
    "preposition",
    "verb",
  ],
};
