import React from "react";
import "./modal.css"; // import your modal styles

const Modal = ({ isOpen, onClose, children }) => {
  const handleModalClick = (event) => {
    event.stopPropagation();
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content" onClick={handleModalClick}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
