import React from "react";
import { Link } from "react-router-dom";
import AppContainer from "../../containers/AppContainer";

const Home = () => {
  const linksLeft = [
    {
      to: "/lcBookings",
      title: "Live Class booking report for SOLS 24/7",
      details: "Get the latest bookings from SOLS 24/7 choose date range and click on the button to get the report",
    },
    {
      to: "/waiting-list-report",
      title: "Waiting List Report for SOLS 24/7",
      details: "Get the waiting list per class from SOLS 24/7 choose date range and click on the button to get the report",
    },
  ];
  const linksRight = [
    {
      to: "/id-generator",
      title: "Generate Ids (UUID, NANOID, FBID)",
      details: "check out bookings",
    },
    {
      to: "/fake-data-generator",
      title: "Generate Fake Data",
      details:
        "Generate massive amounts of fake (but realistic) data for testing and development.\n" +
        "\n",
    },
    // {
    //   to: "/generate-cs-data",
    //   title: "Generate Charge Singh Mock Data",
    //   details:
    //     "Generate massive amounts of fake (but realistic) data for testing and development.\n" +
    //     "\n",
    // },
  ];

  return (
    <AppContainer headerTitle={"Dashboard"}>
      <div className="container">
        <div className="row">
          <div className="column">
            {linksLeft.map((link) => (
              <div className={"card links"} key={link.to} >
                <Link to={link.to}>
                  <b> {link.title} </b>
                  <br />
                  <small>{link.details}</small>
                </Link>
              </div>
            ))}
          </div>
          <div className="column">
            {linksRight.map((link) => (
              <div key={link.to} className={"card links"}>
                <Link to={link.to}>
                  <b> {link.title} </b>
                  <br />
                  <small>{link.details}</small>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AppContainer>
  );
};

export default Home;
