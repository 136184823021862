import React, {ChangeEvent, FC, useState} from 'react'
import {useHistory} from 'react-router-dom';
import fbAuth, {loginWithEmail} from '../../../../services/fbAuth';
import {User} from '../../../../interfaces';
import logo from '../../../../logo.svg'


const Form: FC = () => {

  const [form, setForm] = useState<User>({} as User);
  const [alertMsg, setAlertMsg] = useState("");

  const history = useHistory();

  const handleChange = (e: any, input: string) => {
    e.preventDefault();
    const value = e.target.value;
    switch (input) {
      case 'email':
        setForm({...form, email: value});
        break;
      case 'password':
        setForm({...form, password: value});
        break;
    }
  }

  function showAdminUI() {
    history.push('/');
  }

  function showRegularUI() {

    setAlertMsg("Only Teachers and Admins can login.")
    fbAuth.signOut();
    history.push('/login');
  }

  const handleSubmit = async (e: ChangeEvent) => {
    e.preventDefault();
    loginWithEmail(form).then((myUser) => {
      showAdminUI();


      // myUser.user?.getIdTokenResult()
      //     .then((idTokenResult) => {
      //
      //         if (!!idTokenResult.claims.role) {
      //
      //         } else {
      //
      //             showRegularUI();
      //         }
      //     })
      //     .catch((error) => {
      //         console.log(error);
      //     });


    }).catch(error => {
      console.log(error);

      if (error) {
        alert(error.message);
      }
    })
  }

  return (<div className={"container"}>
      <div className={"row"}>
        <div className="column">

        </div>

        <div className={"column column-50 "}>
          <div className="row">
            <div className="column">

            </div>
            <div className="column">
              <img alt={"Sols 24/7"} src={logo} width={100} height={'auto'}/>

            </div>
            <div className="column">

            </div>
          </div>


          <form noValidate>


            <input
              required
              id="email"
              name="email"
              autoComplete="email"
              autoFocus
              onChange={e => handleChange(e, 'email')}


            />

            <input
              required
              name="password"
              type="password"
              id="password"
              autoComplete="current-password"

              onChange={e => handleChange(e, 'password')}

            />


            <button
              type="submit"
              color="primary"

              onClick={(e: any) => handleSubmit(e)}
            >
              Sign In
            </button>
         

          </form>

        </div>
        <div className="column">

        </div>
      </div>
    </div>

  )
}


export default Form
