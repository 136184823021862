import React, {FC} from 'react'
import Form from './components/Form';


const Login: FC = () => {

  return (
    <div className={"row"}>
      <div
        className={"column"}
      >

        <Form/>

      </div>
    </div>
  )
}

export default Login
