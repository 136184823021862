import React, {useEffect, useRef, useState} from 'react';
import AppContainer from "../../containers/AppContainer";
import fbAuth from "../../services/fbAuth";


const usersSagita = [
  {
    "name": "Salina Anizar",
    "userEmail": "achiksal95@gmail.com",
    "userId": "C3QKqoPTPmPCp4hjnP78zn8ofNB2",
    "batch": 1
  },
  {
    "name": "Fredrick Sukom Anak Wilson",
    "userEmail": "fredricksukom27@gmail.com",
    "userId": "3iYFDgOgSOfvCL0bGJkHddQXFTo1",
    "batch": 1
  },
  {
    "name": "Nurul Norizzati Binti Ismail",
    "userEmail": "akakzaty@gmail.com",
    "userId": "p9q8Unp64bMQg6ltu7IXd1n5D6Z2",
    "batch": 1
  },
  {
    "name": "Siti Najwa Binti Mohammad Ahyat",
    "userEmail": "siti.ahyat@gmail.com",
    "userId": "QhgwgD5mS4QSASvH94CWVfPFeVu2",
    "batch": 1
  },
  {
    "name": "Saidatul Nursyida Mat Rahim",
    "userEmail": "saidatulnursyida@gmail.com",
    "userId": "0qap8g6aDUUjuiqVsLIu9s4jFug2",
    "batch": 1
  },
  {
    "name": "Tuan Shahira Amalin Binti Tuan Yunazri",
    "userEmail": "tia190029@siswa.um.edu.my",
    "userId": "8QXgK1gu55d825Wcdyxs2UJ8Nyr2",
    "batch": 1
  },
  {
    "name": "Aiman Yusuf Ibrahim",
    "userEmail": "manyuw7@gmail.com",
    "userId": "g87xhZGONyaLQbNhAzkwwYI5kqk2",
    "batch": 1
  },
  {
    "name": "Siti Nur Asyiqin Binti Mohammad",
    "userEmail": "asyiqinmhmmd@gmail.com",
    "userId": "4vrnDquGHAXpXSNeeeeb5iJyQgg1",
    "batch": 1
  },
  {
    "name": "Prassanah A/P Arumugam",
    "userEmail": "prasannah0408@gmail.com",
    "userId": "bnqtd6l3RzTCwQvDJ3ptmpDv2LC3",
    "batch": 1
  },
  {
    "name": "Yuvarani Vinayagam",
    "userEmail": "mageswary_mages@ymail.com",
    "userId": "JcZm9yVLn7d3mfIeH7hD31L3o0e2",
    "batch": 1
  },
  {
    "name": "Afiqah",
    "userEmail": "afiqahraif97@gmail.com",
    "userId": "mHmmJZZBPVZJFbfj05bAhMnh8JO2",
    "batch": 1
  },
  {
    "name": "Nurul Fazilatul Najwa Binti Adbul Sani",
    "userEmail": "fazilatulnajwa25@gmail.com",
    "userId": "At65h5NtXQMWgdCfKlojvouh8kE2",
    "batch": 1
  },
  {
    "name": "Nor Elyliana",
    "userEmail": "elyelyana27@gmail.com",
    "userId": "UfW8JOWs7ybZ4mXRbb2okxs4W5F2",
    "batch": 1
  },
  {
    "name": "Muhammad Amar Aizad Bin Azmi",
    "userEmail": "amarey96@gmail.com",
    "userId": "4E13xIoGlUTJJQty0y1zVbBtgrC3",
    "batch": 1
  },
  {
    "name": "Khor Shandy",
    "userEmail": "sdykr1910@gmail.com",
    "userId": "amCMR1PdnDPU0cCnlaUZO1h4pDU2",
    "batch": 1
  },
  {
    "name": "Zureen Aliyah Binti Azmel Effendy",
    "userEmail": "Zrnaliyah12@gmail.com",
    "userId": "SHpUDnp5orYAXpUyJft5F1ZrL9m1",
    "batch": 1
  },
  {
    "name": "Nurdiana Bt Mohd Razif",
    "userEmail": "dianarazif27@gmail.com",
    "userId": "NXxt2DBTEda162m7tcjZcQeb7kA2",
    "batch": 2
  },
  {
    "name": "NUR E'ZZATI BINTI ABD MAJID",
    "userEmail": "nurezzati2607@gmail.com",
    "userId": "",
    "batch": 2
  },
  {
    "name": "SITI FATIMAH OSMAN",
    "userEmail": "sfatimah@iium.edu.my",
    "userId": "JHkR30r67BYODjAWdEu5rBVf3UF3",
    "batch": 2
  },
  {
    "name": "AIMI ATHIRAH BINTI AZHAR",
    "userEmail": "aimiathirahazhar@gmail.com",
    "userId": "R0jyqSPFDRMv9oiRHOc4DmcG2U63",
    "batch": 2
  },
  {
    "name": "SAMSIAH BINTI HASSAN A GANI",
    "userEmail": "samsiahhassan96@gmail.com",
    "userId": "14xsuQD4d7ODd0m3KTOLzgqufQk2",
    "batch": 2
  },
  {
    "name": "NUR ZAITIE SHAFIRA BINTI MOHD ZAINI",
    "userEmail": "shashafyra@yahoo.com",
    "userId": "P0faX5Pb1OQnLfL3FCIotkGAxqg2",
    "batch": 2
  },
  {
    "name": "Theinmoli Munusamy",
    "userEmail": "thein.moli@gmail.com",
    "userId": "iyjTm9SqTVfITafdUV44X4jFlx33",
    "batch": 2
  },
  {
    "name": "Petrina A/P Pita",
    "userEmail": "petrinapeter0640@gmail.com",
    "userId": "8CzFSEokYXWPVvGPWbvQrINuOvp2",
    "batch": 2
  },
  {
    "name": "Ziffa Binti Zubir",
    "userEmail": "ziffabintizubir@gmail.com",
    "userId": "AalqBVNV0FQesQsllS9nPuKe3WN2",
    "batch": 2
  },
  {
    "name": "Nurulaini binti Abdullah",
    "userEmail": "nrlainiabdllh96@gmail.com",
    "userId": "NsKzmbq9qFew6EoBxCCWB0XjRnB2",
    "batch": 2
  },
  {
    "name": "SITI ZAHIDAH BT ABD MALIK",
    "userEmail": "zaheedamalek99@gmail.com",
    "userId": "AIe8YnDh5lNDoGjXMoT1CVMSLNX2",
    "batch": 2
  },
  {
    "name": "HUSNA NAJIHAH BINTI MOHD ZAINI",
    "userEmail": "husnanajihahzaini@gmail.com",
    "userId": "f7cTNPQ10qSn9HfD7Q9CzOJwle82",
    "batch": 2
  },
  {
    "name": "MASLI KARTIKA",
    "userEmail": "maslikartika651@gmail.com",
    "userId": "",
    "batch": 2
  },
  {
    "name": "KALISWARY A/P ATCHUTHA NAIR",
    "userEmail": "s191032405@studentmail.unimap.edu.my",
    "userId": "mNEbgddnLPfeNIm88OvAb0sBIxp2",
    "batch": 2
  },
  {
    "name": "Thurkarthini Suraskumar",
    "userEmail": "thurkarthini01@gmail.com",
    "userId": "",
    "batch": 3
  },
  {
    "name": "SOFIA BINTI MOHD MATLIH",
    "userEmail": "sofiamatlih@gmail.com",
    "userId": "S0bJ7F8V3qbrOyc4lVzdRg2zf5L2",
    "batch": 3
  },
  {
    "name": "NURSHAHIRAH BINTI KHAIRUL AKMAL",
    "userEmail": "iera.akmal97@gmail.com",
    "userId": "xTP4w43wkShJdfAN1nAxauBIPOV2",
    "batch": 3
  },
  {
    "name": "SITI NUR ATIKAH BINTI YUSSAINI",
    "userEmail": "Ika_jamal@yahoo.com",
    "userId": "QvuxiEvEsGcy0NJUCM1kghyIrpM2",
    "batch": 3
  },
  {
    "name": "Muhammad Fitri Bin Abdul Rashid",
    "userEmail": "muhammadxfitri@gmail.com",
    "userId": "4LQckkL7xBNeSiEZjzMVqtDc4yf2",
    "batch": 3
  },
  {
    "name": "PRIYASINI KANDAH ALANGARAM",
    "userEmail": "priyasini8888@gmail.com",
    "userId": "OeFmfKD2zraHGURgOLrWySBNnq03",
    "batch": 3
  },
  {
    "name": "Kaviyarasi Thamil chelvan",
    "userEmail": "kaviyarasithamilchelvan150998@gmail.com",
    "userId": "bXEApO1rJPYWZv7xNFT9Xv9VOmQ2",
    "batch": 3
  },
  {
    "name": "Eliya Nabila Binti Firhat",
    "userEmail": "eliyanabila96@gmail.com",
    "userId": "M3SAF2S3LcRO0hFihesaslwv8RB3",
    "batch": 3
  },
  {
    "name": "SARINAH A/P ZAINAL",
    "userEmail": "sarinahzainal73@gmail.com",
    "userId": "",
    "batch": 3
  },
  {
    "name": "AYADI MUHAJIR BIN KHUSAIRI",
    "userEmail": "ayadimuhajir@gmail.com",
    "userId": "PObgxAyBCyU4eAiw3zCW2lSee3k1",
    "batch": 3
  },
  {
    "name": "Nur Anisah Najwa Binti Zamri",
    "userEmail": "nuranisahnajwa.zamri@gmail.com",
    "userId": "",
    "batch": 3
  },
  {
    "name": "Srivathani a/p Palaniappan",
    "userEmail": "srivathanibk@gmail.com",
    "userId": "",
    "batch": 3
  },
  {
    "name": "Mohd Azwan Bin Zulkafri",
    "userEmail": "azwan.zulkafri@gmail.com",
    "userId": "",
    "batch": 3
  },
  {
    "name": "TAHNASIRI",
    "userEmail": "srithana20@gmail.com",
    "userId": "",
    "batch": 3
  },
  {
    "name": "NOR HIDAYAH BINTI JUSILI",
    "userEmail": "Ayukiyuq@gmail.com",
    "userId": "KLDYZlxePkU3suKWBKdz80debg02",
    "batch": 3
  },
  {
    "name": "MUHAMMAD FAUZI BIN ABDULLAH",
    "userEmail": "fauziabdullah321@gmail.com",
    "userId": "p4RoM6caENdnSEXJd6Gi6hITLNS2",
    "batch": 4
  },
  {
    "name": "Lily Khalidatussoleha binti Mohamad Khalid",
    "userEmail": "lksoleha@gmail.com",
    "userId": "cKxUbJa0TAenCYC40SzlvD4ttiL2",
    "batch": 4
  },
  {
    "name": "Subashini A/P Murugan",
    "userEmail": "subashinimurugan2000@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "DEVIKA A/P CHANDRAN",
    "userEmail": "sairamdaughter@gmail.com",
    "userId": "qbWeMztdpabCVw9EXdpnvaxpBb62",
    "batch": 4
  },
  {
    "name": "AN NISSA BINTI AHAMAD BASTAMAM",
    "userEmail": "nissa.bastamam@gmail.com",
    "userId": "IXHfSG3AhlWGOhn0fKV0HJRpZYF3",
    "batch": 4
  },
  {
    "name": "LEE YUNG MING",
    "userEmail": "ymhere1206@gmail.com",
    "userId": "HC3HoP8Fepe7IRFYIMfxDIzhcL62",
    "batch": 4
  },
  {
    "name": "FIRZANA BINTI MOHD FIRDAUS@AHMADI",
    "userEmail": "firzanafir@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "NORFAZLIN BINTI MANSOR",
    "userEmail": "norfazlinmansor@gmail.com",
    "userId": "auX2hwsWf4eoeVyJUKWUFIxaOIr2",
    "batch": 4
  },
  {
    "name": "Yuvanes Narayanasamy",
    "userEmail": "yuvaneshnarayanasamy94@gmail.com",
    "userId": "1gOAcXbSweW03JGLYafQK6YdzOG2",
    "batch": 4
  },
  {
    "name": "NOOR ZAIDAH BINTI ANUWAR",
    "userEmail": "noorzaidahanuwar@gmail.com",
    "userId": "bqgEPbq0tMeNnf4cvD6aJatHNa23",
    "batch": 4
  },
  {
    "name": "Adib Hazim Bin Muhamad Ismail",
    "userEmail": "adib14.hazim@gmail.com",
    "userId": "Nn0P33LtnFPYCIT2nKsUrcRBc0j1",
    "batch": 4
  },
  {
    "name": "Humairatul Barirah Bt Mohd Jefri",
    "userEmail": "airaamohdjefr@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "Rabia shahira binti zamri",
    "userEmail": "rabiashahira@gmail.com",
    "userId": "rSwuYj8OfaQP5uXuBD462T4qH8f2",
    "batch": 4
  },
  {
    "name": "REMASHINI BALA A/P BALA SUBRAMANIAM",
    "userEmail": "remashinibala@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "Andi Akhma Binti Andi Anwar",
    "userEmail": "andiakhma3@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "Ahmad Fadhlul Hadi Bin Azmi",
    "userEmail": "nabilazmi2089@gmail.com",
    "userId": "NVbaXzUNvFQVJBqyTYIRng8bfA83",
    "batch": 4
  },
  {
    "name": "YOGESWARI A/P DEVENTHIRAN",
    "userEmail": "yogeswarideventhiran96@gmail.com",
    "userId": "OYmP7pFLrzWGlbDPdBo16d0R9to2",
    "batch": 4
  },
  {
    "name": "NAREEND RAO A/L MOHANAN",
    "userEmail": "nareendrao28@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "Nurul Abidah binti Ahmad",
    "userEmail": "nurulahmad1022@gmail.com",
    "userId": "",
    "batch": 4
  },
  {
    "name": "NUR FITRIANA BINTI IBRAHIM",
    "userEmail": "Fitttibrahim@gmail.com",
    "userId": "4CG39aAoYXPKqNFO6JdSBSJbZKA3",
    "batch": 4
  },
  {
    "name": "NUR SYAHIDA BINTI SUYOT",
    "userEmail": "syiedasuyot@gmail.com",
    "userId": "qUmfwD9eNch7eBQNwS3DqJoWnIi1",
    "batch": 4
  },
  {
    "name": "MUHAMMAD AKMAL BIN SUKRI",
    "userEmail": "akmalsukri436@gmail.com",
    "userId": "3NgfCYusbbTOLZLcmCvf9wvre6i2",
    "batch": 4
  },
  {
    "name": "Muhammad Haniff bin Rizal",
    "userEmail": "Haniffrizal999@gmail.com",
    "userId": "htfXjlpsGgNYi4ioMtyZnz3FCvx1",
    "batch": 4
  }
]

export interface StudentInterface {
  name: string;
  userEmail: string;
  userId: string;
  batch: number;
  modulesCompleted: number
  lessonsCompleted: number
}

const ClassReportByUid = () => {

  const textAreaRef = useRef();
  const [emailList, setEmailList] = useState("");


  // const getUserCourseScoreByUserId = async (userId: string) => {
  //   const userRecord = await auth.getUser(userId);
  //   const userEmail = userRecord.email || "No Email";

  //   const dataJson = await fbAuth.d
  //     .ref("userScore/" + userId + "/weeClass")
  //     .once("value");
  //   if (dataJson.exists()) {
  //     let lessonCompletedCount = 0;
  //     let moduleCompletedCount = 0;
  //     let courseGrade = "D";
  //     let courseScore = 0;
  //     const snapshot = dataJson.val();
  //     const lesson = snapshot.lesson;
  //     const module = snapshot.module;
  //     const course = snapshot.course;
  //     courseScore = course.courseScore;
  //     courseGrade = course.courseGrade;

  //     Object.keys(lesson).forEach(function (key) {
  //       // console.log(key, lesson[key].lessonScore);
  //       if (lesson[key].lessonScore >= 0.7) {
  //         lessonCompletedCount++;
  //       }
  //     });

  //     Object.keys(module).forEach(function (key) {
  //       // console.log(key, module[key].moduleScore);
  //       if (module[key].moduleScore >= 0.7) {
  //         moduleCompletedCount++;
  //       }
  //     });

  //     // console.log(courseScore);
  //     // console.log(courseGrade);

  //     htmlData +=
  //       "<tr><td>" +
  //       userId +
  //       "</td><td>" +
  //       userEmail +
  //       "</td><td>Progress:" +
  //       (courseScore * 100).toFixed(3) +
  //       " % Lessons Completed: " +
  //       lessonCompletedCount +
  //       " Modules Completed: " +
  //       moduleCompletedCount +
  //       " Grade:" +
  //       courseGrade +
  //       "</td></tr>";

  //     // console.log(htmlData)
  //   } else {
  //     console.log("no data");
  //   }
  // };

  // async function exportUserCourseScore() {
  //   for (const userIdArrayKey of userIdArray) {
  //     console.log(userIdArrayKey);
  //     await getUserCourseScoreByUserId(userIdArrayKey);
  //   }
  // }


  useEffect(() => {
    return () => {
      //get details

    };
  }, []);


  function handleClick() {
    console.log(textAreaRef.current);
  }

  function handleOnChange(e: any) {
    if (e.target.value) {
      setEmailList(e.target.value.split('\n'));
      console.log(emailList);

    }
  }


  return (
    <AppContainer headerTitle={"Class Report"}>
      <div className="container">
        <div className="row">
          <div className="column">

          <textarea ref={textAreaRef.current}
                    value={textAreaRef.current}
                    className="textarea"
                    placeholder="Paste the List of users emails on each line"/>
            <button className="button button-outline" onClick={handleClick}>Submit</button>
          </div>

        </div>

        <div className="row">
          <table>
            <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Modules Completed</th>
              <th>Lessons Completed</th>
              <th>Course Progress</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>John</td>
              <td>John</td>
              <td>John</td>
              <td>John</td>
              <td>John</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </AppContainer>
  );
}


export default ClassReportByUid;
